<template>
  <div>
    <v-app-bar
      fixed
      app
      color="black"
      dark
      :min-height="$vuetify.breakpoint.smAndDown ? '150' : '70'"
    >
      <div class="d-flex align-center" v-if="!$vuetify.breakpoint.smAndDown">
        <v-img
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="70"
          src="https://transform.octanecdn.com/fitLogo/392x260/https://octanecdn.com/jbweldcom/jbweldcom_294558229.png"
          width="70"
        />
      </div>
      <v-container fluid class="mt-5">
        <v-row>
          <v-col cols="12" md="6" class="pb-0">
            <v-text-field
              class="sarabun-regular pb-0"
              outlined
              dense
              label="Enter Location"
              v-model="location"
              ref="locationInput"
              @change="fetchRetailers"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pb-0"
            :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : ''"
          >
            <v-autocomplete
              class="sarabun-regular pb-0"
              :class="$vuetify.breakpoint.smAndDown ? 'pt-0' : ''"
              outlined
              clearable
              dense
              :items="skuList"
              item-text="name"
              item-value="sku"
              v-model="selectedSku"
              label="Select Product"
              @change="fetchRetailers"
            >
              <template v-slot:item="{ item }">
                <v-list-item-action>
                  <v-img :src="item.image" height="50" width="50"></v-img>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="'SKU: ' + item.sku"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <div class="">
      <v-row
        class=""
        :style="{ paddingTop: !$vuetify.breakpoint.smAndDown ? '0px' : '25px' }"
      >
        <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown">
          <div style="border: solid 1px #e8e8e8" class="ml-5">
            <v-skeleton-loader
              tile
              v-bind="attrs"
              type="image, actions"
              class="border-sm border-primary"
              v-if="!groupedRetailers.length"
            ></v-skeleton-loader>
          </div>

          <v-row
            justify="center"
            v-if="
              groupedRetailers.length === this.names.length &&
              this.markers.length > 0
            "
            width="100%"
          >
            <v-col>
              <v-virtual-scroll
                :bench="benched"
                :items="groupedRetailers"
                item-height="210"
                max-height="100vh"
              >
                <template v-slot:default="{ item }">
                  <v-card outlined height="200" class="ml-5 mt-3 mb-3">
                    <v-container>
                      <v-list-item-content>
                        <v-card flat tile height="75">
                          <v-row>
                            <v-col>
                              <h3 class="sarabun-regular">
                                <strong>{{
                                  handleRetailerName(item.name)
                                }}</strong>
                              </h3>
                            </v-col>
                            <v-col>
                              <v-img
                                max-height="90"
                                max-width="90"
                                v-if="item.group !== 'Home Depot'"
                                :src="getLogo(item.name)"
                              />
                              <v-img
                                height="50"
                                width="50"
                                v-if="item.group === 'Home Depot'"
                                :src="getLogo(item.name)"
                              />
                            </v-col>
                          </v-row>
                          <h4 class="sarabun-regular">
                            {{ item.distance }} Miles
                          </h4> </v-card
                        >`
                        <v-card flat tile class="mt-5">
                          <v-card-actions>
                            <v-row>
                              <v-col>
                                <v-btn
                                  class="sarabun-regular"
                                  width="100%"
                                  depressed
                                  dark
                                  color="#e31836"
                                  tile
                                  :href="item.retailers[0].link"
                                  target="_blank"
                                  @click="
                                    postEvent('Retailer PDP View', item.name)
                                  "
                                  >Buy Online</v-btn
                                >
                              </v-col>
                              <v-col>
                                <v-btn
                                  class="sarabun-regular"
                                  text
                                  outlined
                                  tile
                                  width="100%"
                                  color="secondary"
                                  @click="getDirections(item.retailers[0])"
                                  >Get Directions</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card-actions>
                        </v-card>
                      </v-list-item-content>
                    </v-container>
                  </v-card>
                </template>
              </v-virtual-scroll>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 9">
          <div v-if="$vuetify.breakpoint.smAndDown">
            <v-skeleton-loader
              tile
              v-bind="attrs"
              type="image, actions"
              class="border-sm border-primary"
              v-if="
                names.length === groupedRetailers.length &&
                !$vuetify.breakpoint.smAndDown
              "
            ></v-skeleton-loader>
            <div id="map"></div>
          </div>
          <v-container fluid v-if="!$vuetify.breakpoint.smAndDown">
            <div id="map"></div>
          </v-container>
        </v-col>
      </v-row>
      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        mandatory
        grow
        min-height="60"
        class="pb-4"
      >
        <v-btn
          text
          tile
          flat
          value="favorites"
          @click="showSidebar = true"
          v-if="
            groupedRetailers.length === names.length &&
            groupedRetailers.length > 0
          "
        >
          <i class="fas fa-store"
            ><span class="ml-3 sarabun-regular"
              >{{ retailers.length }} Stores Nearby</span
            ></i
          >
        </v-btn>
      </v-bottom-navigation>
      <v-bottom-sheet
        v-model="showSidebar"
        :max-height="'80vh'"
        width="100%"
        v-if="$vuetify.breakpoint.smAndDown && loaded && userLat && userLng"
        class="mobile-bottom-sheet"
      >
        <v-list dense>
          <v-row>
            <v-col cols="8"></v-col>
            <v-col>
              <v-btn
                color="white"
                elevation="0"
                tile
                block
                right
                @click="showSidebar = false"
              >
                <i class="fas fa-close"></i>
              </v-btn>
            </v-col>
          </v-row>
          <v-list-item
            v-for="(group, index) in groupedRetailers"
            :key="index"
            dense
            class="mx-auto"
          >
            <v-row
              width="100%"
              class=""
              style="border-bottom: #e8e8e8 solid 1px"
            >
              <v-col>
                <v-card-title>
                  <v-avatar class="mr-5" tile max-width="50">
                    <img :src="getLogo(group.name)" />
                  </v-avatar>
                  <h4>{{ handleRetailerName(group.name) }}</h4>
                  <v-spacer></v-spacer>
                  <h6>{{ group.distance }} Miles</h6>
                </v-card-title>

                <v-row>
                  <v-col>
                    <v-btn
                      x-small
                      class="sarabun-regular"
                      width="100%"
                      :href="group.retailers[0].link"
                      target="_blank"
                      dark
                      text
                      color="#e31836"
                      tile
                      @click="postEvent('Retailer PDP View', group.name)"
                      >Buy Online</v-btn
                    >
                  </v-col>
                  <v-col>
                    <v-btn
                      x-small
                      width="100%"
                      class="sarabun-regular"
                      text
                      tile
                      color="secondary"
                      @click="getDirections(group.retailers[0])"
                      >Get Directions</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-bottom-sheet>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",

  components: {},
  data: () => ({
    benched: 0,
    currentLat: null,
    currentLng: null,
    loaded: false,
    showSidebar: false,
    location: "",
    selectedSku: "",
    selectedItem: null,
    skuList: [],
    retailers: [],
    names: [],
    highlightedRetailer: null,
    map: null,
    markers: [],
    userLat: null,
    userLng: null,
    userId: null,
    sessionId: null,
    attrs: {
      class: "mb-6",
      boilerplate: false,
      elevation: 0,
    },
    logos: [
      {
        key: "Walmart",
        img: "https://47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io/f1737003616885x478667683216538200/Walmart_logo_%282025%3B_Stacked_alt%29.svg.png?_gl=1*kj1j8u*_gcl_au*NjEzMDI0ODE3LjE3MzY4OTEyOTU.*_ga*MTM3ODkwNjg1Mi4xNzMxODI5ODM4*_ga_BFPVR2DEE2*MTczNjg4Nzg1NS4xMS4xLjE3MzcwMDM2MDkuNTEuMC4w",
      },
      {
        key: "ACE",
        img: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io%2Ff1722749356062x824326544130572900%2FACE-Hardware-logo%2520%25281%2529.png?w=192&h=121&auto=compress&dpr=2&fit=max",
      },
      {
        key: "Home Depot",
        img: "https://47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io/f1722749367772x826966988823754100/71BwXJ6rBKL.jpg?_gl=1*i0aq5v*_gcl_au*NjEzMDI0ODE3LjE3MzY4OTEyOTU.*_ga*MTM3ODkwNjg1Mi4xNzMxODI5ODM4*_ga_BFPVR2DEE2*MTczNzA3MjY5MS4xMi4xLjE3MzcwNzMyNTcuNTEuMC4w",
      },
      {
        key: "Lowes",
        img: "https://d1muf25xaso8hp.cloudfront.net/https%3A%2F%2F47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io%2Ff1722749443154x426988598549466400%2FLowesLogo_RGB%2520%25281%2529.png?w=192&h=121&auto=compress&dpr=2&fit=max",
      },
      {
        key: "OReilly",
        img: "https://47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io/f1737005286389x476419452020097700/O%2527Reilly_Auto_Parts_1993_logo%20%281%29.webp?_gl=1*r5hson*_gcl_au*NjEzMDI0ODE3LjE3MzY4OTEyOTU.*_ga*MTM3ODkwNjg1Mi4xNzMxODI5ODM4*_ga_BFPVR2DEE2*MTczNjg4Nzg1NS4xMS4xLjE3MzcwMDM2MDkuNTEuMC4w",
      },
      {
        key: "AutoZone",
        img: "https://logos-world.net/wp-content/uploads/2022/04/AutoZone-Symbol.png",
      },
      {
        key: "Advance Auto Parts",
        img: "https://47e04796a3a89b83d7e9b07e411135ac.cdn.bubble.io/f1737701453387x103214914910318980/Group%20130.png?_gl=1*38zt71*_gcl_au*NjEzMDI0ODE3LjE3MzY4OTEyOTU.*_ga*MTM3ODkwNjg1Mi4xNzMxODI5ODM4*_ga_BFPVR2DEE2*MTczNzcwMTEyNC4xNi4xLjE3Mzc3MDIyNTkuMzkuMC4w",
      },
    ],
  }),
  watch: {
    selectedSku(val) {
      this.selectedItem = this.skuList.filter(
        (item) => item.sku === this.selectedSku
      )[0];
      this.updateURLParams();
    },
    retailers(val) {
      this.loaded = this.groupedRetailers.length === this.names.length;
    },
    async location(val) {
      const current = await this.getLatLngFromAddress(val);
      this.currentLat = current.lat;
      this.currentLng = current.lng;
    },
  },
  computed: {
    isLoaded() {
      return this.location && this.groupedRetailers.length > 0;
    },
    groupedRetailers() {
      const groups = {};
      this.retailers.forEach((retailer) => {
        if (!groups[retailer.retailer_name]) {
          groups[retailer.retailer_name] = {
            name: retailer.retailer_name,
            retailers: [],
          };
        }
        groups[retailer.retailer_name].distance =
          groups[retailer.retailer_name].retailers.push(retailer);
      });
      return Object.values(groups)
        .map((group) => {
          const closestDistance = Math.min(
            ...group.retailers.map((retailer) =>
              this.calculateDistance(retailer.address.lat, retailer.address.lng)
            )
          );
          group.distance = closestDistance;
          return { ...group, closestDistance }; // Attach the closest distance to the group
        })
        .sort((a, b) => a.distance - b.distance);
    },
  },
  methods: {
    toggleBottomSheet() {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showSidebar = !this.showSidebar;
      }
    },
    init() {
      let userId = localStorage.getItem("userId");

      // If not, generate and store one
      if (!userId) {
        userId = this.generateUserId();
        localStorage.setItem("userId", userId);
      }
      gtag("set", { user_id: userId });
      this.userId = userId;
    },
    async getLatLngFromAddress(address) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address }, (results, status) => {
          if (status === "OK" && results[0]) {
            const location = results[0].geometry.location;
            resolve({
              lat: location.lat(),
              lng: location.lng(),
            });
          } else {
            console.error(`Geocode failed: ${status}`);
            reject(new Error(`Geocode failed: ${status}`));
          }
        });
      });
    },
    calculateDistance(addressLat, addressLng) {
      const toRadians = (degrees) => degrees * (Math.PI / 180);
      const earthRadiusMiles = 3958.8; // Radius of Earth in miles
      const dLat = toRadians(addressLat - this.currentLat);
      const dLng = toRadians(addressLng - this.currentLng);

      const lat1 = toRadians(this.userLat);
      const lat2 = toRadians(addressLat);

      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) *
          Math.cos(lat2) *
          Math.sin(dLng / 2) *
          Math.sin(dLng / 2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = earthRadiusMiles * c;
      return distance.toFixed(2); // Return the distance in miles, rounded to 2 decimal places
    },
    fetchGA4SessionAndUserId() {
      // Fetch session ID
      gtag("get", "G-DD9K9M9485", "session_id", (session_id) => {
        this.sessionId = session_id;

        // Fetch user ID once session ID is available
        gtag("get", "G-DD9K9M9485", "user_id", (user_id) => {
          this.userId = user_id;
        });
      });
    },
    fetchGA4SessionId() {
      gtag("get", "G-DD9K9M9485", "session_id", (session_id) => {
        this.sessionId = session_id;
      });
    },
    generateUserId() {
      // Simple UUID v4 generation
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getLogo(retailerName) {
      return this.logos.filter((logo) => logo.key === retailerName)[0].img;
    },
    initializeMap() {
      this.map = new google.maps.Map(document.getElementById("map"), {
        center: {
          lat: this.userLat || 37.7749,
          lng: this.userLng || -122.4194,
        },
        zoom: 12,
        disableDefaultUI: true,
        zoomControl: true,
      });

      if (this.location) {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: this.location }, (results, status) => {
          if (status === "OK" && results[0].geometry.location) {
            this.map.setCenter(results[0].geometry.location);
          }
        });
      }

      const autocomplete = new google.maps.places.Autocomplete(
        this.$refs.locationInput.$el.querySelector("input")
      );
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        if (place.geometry) {
          this.location = place.formatted_address;
          this.map.setCenter(place.geometry.location);
          this.updateURLParams();
          this.fetchRetailers();
        }
      });
    },
    getDirections(retailer) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&destination=${retailer.address.lat},${retailer.address.lng}`
      );
    },
    async buyOnline(retailer) {
      this.postEvent("Retailer PDP View", retailer.retailer_name);
      const sku = await this.fetchStoreSku(retailer);

      // Use an anchor element to avoid popup blockers
      const link = document.createElement("a");
      link.href = sku;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();

      return sku;
      // const sku = await this.fetchStoreSku(retailer);
      // //alert(JSON.stringify(sku));
      // window.open(sku, "_blank");
      // return sku;
    },
    async fetchStoreSku(store) {
      //                    alert(JSON.stringify(store._id))
      return await axios
        .post(
          `https://web.jbweld.com/api/1.1/wf/sku?store_id=${store._id}&sku=${this.selectedSku}`
        )
        .then((res) => {
          //    alert(JSON.stringify(res.data.response.sku.link))
          return res.data.response.sku.link;
        });
    },
    fetchSkuList() {
      axios
        .get("https://web.jbweld.com/api/1.1/obj/product_lookup")
        .then((response) => {
          const data = response.data.response.results
            .map((product) => ({
              sku: product.sku,
              name: product.name,
              ...product,
            }))
            .filter((item) => item.active);
          this.skuList = data.sort((a, b) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          });
        })
        .catch((error) => console.error("Error fetching SKUs:", error));
    },
    async getRetailerList() {
      let retailers = [];
      return await axios
        .post(`https://web.jbweld.com/api/1.1/wf/get-retailers`, null, {
          params: {
            sku: this.selectedSku,
            address: this.location,
          },
        })
        .then(async (res) => {
          const data = res.data.response.retailers;
          this.names = data;
          console.log(data);
          for (let i in data) {
            console.log(data[i]);
            retailers.push(data[i]);
            await this.fetchRetailerStores(data[i]);
          }
          return retailers;
        });
    },
    hasAllRetailers(retailers) {
      let hasAll = true;
      let missing = [];
      for (let i in retailers) {
        if (
          this.retailers.filter(
            (retailer) => retailer.retailer_name === retailers[i]
          ).length === 0
        ) {
          missing.push(retailers[i]);
          hasAll = false;
        }
      }
      return { hasAll, missing };
    },
    async fetchRetailerStores(retailer) {
      const items = [];
      await axios
        .post(
          `https://web.jbweld.com/api/1.1/wf/get-stores-by-retailer`,
          null,
          {
            params: {
              sku: this.selectedSku,
              address: this.location,
              retailer: retailer,
            },
          }
        )
        .then((res) => {
          const stores = res.data.response.stores;

          items.push(...stores);
          console.log(items.length);
        })
        .catch((e) => console.log(e));
      const link = await this.fetchStoreSku(items[0]);
      this.retailers.push(...items.map((retailer) => ({ ...retailer, link })));
    },
    async fetchRetailers() {
      if (!this.location || !this.selectedSku) return;
      this.names = [];
      this.retailers = [];
      this.updateURLParams();
      await this.getRetailerList().then((res) => {
        this.addMarkers();
      });
    },
    handleRetailerName(name) {
      if (name === "ACE") {
        return "Ace Hardware";
      } else if (name === "Lowes") {
        return "Lowe's";
      } else if (name === "OReilly") {
        return "O'Reilly Auto Parts";
      } else {
        return name;
      }
    },
    addMarkers() {
      this.clearMarkers();

      const bounds = new google.maps.LatLngBounds(); // Create a bounds object
      const iconSize = this.$vuetify.breakpoint.smAndDown ? 45 : 75;
      this.retailers.forEach((retailer) => {
        const position = {
          lat: retailer.address.lat,
          lng: retailer.address.lng,
        };
        const marker = new google.maps.Marker({
          position,
          map: this.map,
          title: `${this.handleRetailerName(retailer.retailer_name)}\n${
            retailer.address.address
          }`,
          icon: {
            url: retailer.retailer_logo.startsWith("https://")
              ? retailer.retailer_logo
              : "https:" + retailer.retailer_logo,
            scaledSize: new google.maps.Size(iconSize, iconSize), // Adjust size if necessary
          },
        });

        // Extend the bounds to include each marker
        bounds.extend(position);

        const infoWindow = new google.maps.InfoWindow({
          content: `<div><strong>${this.handleRetailerName(
            retailer.retailer_name
          )}</strong><br>${retailer.address.address}</div>`,
        });

        marker.addListener("mouseover", () => {
          infoWindow.open(this.map, marker);
        });

        marker.addListener("mouseout", () => {
          infoWindow.close();
        });
        // Optional: Add marker click listener
        // marker.addListener("click", () => {
        //   this.handleMarkerClick(retailer);
        // });

        this.markers.push(marker);
      });

      // Adjust the map's viewport to fit all markers
      if (this.retailers.length > 0) {
        this.map.fitBounds(bounds);
      } else {
        console.warn("No retailers found to display.");
      }
    },
    clearMarkers() {
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];
    },

    async requestUserLocation() {
      const params = new URLSearchParams(window.location.search);
      const urlLocation = params.get("location");
      const urlSku = params.get("sku");

      // Set location and SKU from URL if available
      if (urlLocation) {
        this.location = urlLocation;
        const coords = await this.getLatLngFromAddress(urlLocation);
        this.userLat = coords.lat;
        this.userLng = coords.lng;
      }

      if (urlSku) {
        this.selectedSku = urlSku;
        this.selectedItem = this.skuList.filter(
          (item) => item.sku === urlSku
        )[0];
      }

      // If location is not in URL, fetch user's location
      if (!urlLocation && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.userLat = position.coords.latitude;
            this.userLng = position.coords.longitude;

            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { location: { lat: this.userLat, lng: this.userLng } },
              async (results, status) => {
                if (status === "OK" && results[0]) {
                  this.location = results[0].formatted_address;
                  this.updateURLParams();
                  this.initializeMap();
                  await this.fetchRetailers();
                } else {
                  console.error("Geocoder failed: ", status);
                  this.initializeMap();
                }
              }
            );
          },
          (error) => {
            console.error("Error getting user location:", error);
            this.initializeMap();
          }
        );
      } else {
        // If no geolocation or location in URL, initialize map with default
        this.initializeMap();
        if (this.location && this.selectedSku) {
          await this.fetchRetailers();
        }
      }
    },

    postEvent(name, retailer) {
      axios
        .post(`https://web.jbweld.com/api/1.1/wf/track`, {
          sku: this.selectedSku,
          retailer: retailer,
          name: name,
          session_id: this.sessionId ?? "test",
          user_id: this.userId ?? "test",
        })
        .then((response) => {
          console.log(response);
        });
    },
    updateURLParams() {
      const params = new URLSearchParams(window.location.search);
      if (this.location) params.set("location", this.location);
      if (this.selectedSku) {
        params.set("sku", this.selectedSku);
        this.selectedItem = this.skuList.filter(
          (item) => item.sku === this.selectedSku
        )[0];
      }

      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`
      );
    },
    updateURLSku(val) {
      const params = new URLSearchParams(window.location.search);
      if (val) params.set("sku", val);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${params.toString()}`
      );
    },
  },
  async mounted() {
    //this.init()
    //this.fetchGA4SessionAndUserId(); // Fetch GA4 session ID when the app is created

    this.fetchSkuList();
    await this.requestUserLocation();
  },
};
</script>
<style>
.v-toolbar__content {
  height: 100% !important;
}
</style>
